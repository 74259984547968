import PropTypes from "prop-types";
import React, {Component} from "react";
import RichTextEditor, {getTextAlignClassName, getTextAlignStyles, getTextAlignBlockMetadata} from 'react-rte';

class Editor extends Component {

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.state = {
            value: RichTextEditor.createValueFromString(this.props.text, this.props.format,  {
                customBlockFn: getTextAlignBlockMetadata,
            })
        }
    }
    onChange = (value) => {
        this.setState({value});
        this.props.updateText(value.toString(this.props.format),{blockStyleFn: getTextAlignStyles});
    };

    render() {
        return (
            <RichTextEditor
                autoFocus={true}
                ref={this.editor}
                value={this.state.value}
                onChange={this.onChange}
                placeholder="Tell a story"
                toolbarConfig={RichTextEditor.toolbarConfig}
                blockStyleFn={getTextAlignClassName}
            />
        );
    }
}


Editor.propTypes =
    {
        text: PropTypes.string,
        format: PropTypes.string,
        updateText: PropTypes.func,
    };
Editor.defaultProps = {
    text: "",
    format: "",
    updateText: () => {
    }
};

export default (Editor)