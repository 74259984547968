import React, { useEffect, useState } from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import { inject, observer } from "mobx-react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import config from "../../config/main.config"
import history from "../../helper/browserHistory"
import { getTokenFromLocalStorage, isAdmin, useWindowSize } from "../../helper/util";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import { Radio } from "semantic-ui-react";

const namespace = "partner";
const SHOW_MORE = 3


function uploadThumbnailImage(event, setPartner, partner) {
    const data = new FormData();
    data.append('image', event.target.files[0]);
    fetch(config.BASE_URL + 'blog/image', {
        method: "POST",
        body: data,
        headers: {
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setPartner({ ...partner, image: json.link })
            });
        }
    }).catch((e) => {
        console.log(e);
    });
}

function fetchAllImages() {
    return new Promise(resolve => fetch(config.BASE_URL + "images/", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                let allImages = [];
                for (let i = 0; i < json.length; i++) {
                    allImages.push({
                        link: json[i]
                    })
                }
                return resolve(allImages)
            });

        } else {
            console.log(response.status)
        }
    }).catch(
        error => {
            console.log(error);

            if (error.status === 401) {
                history.replace("/admin-login");
            }

        }
    ));
}

function savePartner(partner) {
    return new Promise(resolve => fetch(config.BASE_URL + 'partner/', {
        method: partner.id ? "PUT" : "POST",
        body: JSON.stringify({ partner }),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        }
    }).catch((e) => {
        console.log(e);
    }));
}

function deletePartner(id) {
    return new Promise(resolve => fetch(config.BASE_URL + 'partner/' + id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        }
    }).catch((e) => {
        console.log(e);
    }));
}

export function getAllPartner() {
    return new Promise(resolve => fetch(config.BASE_URL + 'partner/', {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return resolve(json);
            });
        }
    }).catch((e) => {
        console.log(e);
    }));
}

const PartnerPage = inject("stores")(observer((props) => {
    const { textStore } = props;
    let applicationWidth = useWindowSize().width
    const [searchString, setSearchString] = useState("");
    const [currentAmount, setCurrentAmount] = useState(2);
    const [shownPartners, setShownPartners] = useState([]);
    const [allPartners, setAllPartners] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState({ active: false, id: "", name: "" });
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [partnerToEdit, setPartnerToEdit] = useState({});
    const [categories, setCategories] = useState(
        [{ name: 'Alle Partner', active: true },
        { name: 'Stiftungen', active: false },
        { name: 'Unternehmen', active: false },
        { name: 'Vereine und Initiativen', active: false },
        { name: 'Kommunen', active: false },
        { name: 'Bildungsträger', active: false }]
    )
    useEffect(_ => {
        window.scrollTo(0, 0);
        props.stores.trackingStore.addVisit("Partner");
        getAllPartner().then(allPartner => {
            setAllPartners(allPartner);
            setShownPartners(allPartner);
        })
    }, []);


    useEffect(() => {
        if (searchString === "") {
            setShownPartners(allPartners.filter(partner => filterPartners(partner)));
            return
        }
        setShownPartners(shownPartners.filter(partner => partner.name.toLowerCase().includes(searchString.toLowerCase())))
    }, [searchString])

    useEffect(_ => {
        if (allImages.length <= 0 && imageModalOpen) {
            fetchAllImages().then(allImages => {
                setAllImages(allImages);
            })
        }
    }, [imageModalOpen])
    const linkExp = new RegExp(/^(https?:\/\/)/)
    const filterPartners = (partner) => {
        //no filter set
        if (categories[0].active) {
            return true
        }
        switch (partner.type) {
            //Stiftungen
            case 0:
                return categories[1].active;
            //Unternehmen
            case 1:
                return categories[2].active;
            //Vereine und Initiativen
            case 2:
                return categories[3].active;
            //Kommunen
            case 3:
                return categories[4].active;
            //Bildungsträger
            case 4:
                return categories[5].active;
            default:
                return true;
        }
    }

    const showImageModal = () => {
        return (
            <Modal open={imageModalOpen}>
                <Modal.Header>Bild auswählen</Modal.Header>
                <Modal.Content>
                    <div style={{ columnCount: 3 }}>
                        {allImages.map((image, index) => {
                            return (
                                <img key={'image ' + index} style={{ cursor: 'pointer' }} onClick={_ => {
                                    setPartnerToEdit({ ...partnerToEdit, image: "blog-images/" + image.link })
                                    setImageModalOpen(false)
                                }} src={config.BASE_IMAGE_URL + "blog-images/" + image.link} alt={image.name} />

                            )
                        })}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color={'red'} onClick={_ => {
                        setImageModalOpen(false)
                    }}>Abbrechen</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    const showEditModal = () => {
        return (
            <>
                <Modal open={editModalOpen}>
                    <Modal.Header>{isNew ? "Neuen Partner erstellen" : `${partnerToEdit.name} bearbeiten`}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <Form.Input label={'Titel'} value={partnerToEdit.name} onChange={e => {
                                    setPartnerToEdit({ ...partnerToEdit, name: e.target.value })
                                }} />
                            </Form.Field>
                            <Form.Field>
                                <Form.TextArea label={'Beschreibung'} value={partnerToEdit.description} onChange={e => {
                                    setPartnerToEdit({ ...partnerToEdit, description: e.target.value })
                                }} />
                            </Form.Field>
                            <Form.Field>
                                <label>Thumbnail</label>
                                <input
                                    className={"input-flied form-control thumbnail-form"}
                                    value={partnerToEdit.image}
                                    type="text"
                                    id="inputThumbnail"
                                    placeholder="URL..."
                                    onChange={e => setPartnerToEdit({ ...partnerToEdit, image: e.target.value })}
                                />
                                <div className="five wide column no-padding" style={{ display: 'flex' }}>
                                    <div className="no-padding thumbnail-form" style={{ width: '50%' }}>
                                        <button
                                            className="thumbnail-form call-to-action big-call-to-action thumbnail-form login-container"
                                            onClick={_ => setImageModalOpen(true)}
                                        >
                                            <b>
                                                AUSWÄHLEN
                                            </b>
                                        </button>
                                    </div>
                                    <div className="no-padding thumbnail-form" style={{ width: '50%' }}>
                                        <input type="file" id="file" className="inputfile thumbnail-form"
                                            onChange={e => uploadThumbnailImage(e, setPartnerToEdit, partnerToEdit)}
                                            accept="image/*" />
                                        <b>
                                            <label htmlFor="file"
                                                className="call-to-action big-call-to-action thumbnail-form login-container">
                                                HOCHLADEN
                                            </label>
                                        </b>
                                    </div>
                                </div>
                            </Form.Field>
                            <Form.Field style={{ display: 'grid' }}>
                                <label>Kategorie</label>
                                {categories.map((category, index) => {
                                    if (index > 0) {
                                        return (
                                            <Radio name={'category-group'} checked={partnerToEdit.type === index - 1}
                                                onChange={_ => setPartnerToEdit({ ...partnerToEdit, type: index - 1 })}
                                                label={category.name} />)
                                    }

                                })}
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label={'Link'} value={partnerToEdit.link} onChange={e => {
                                    setPartnerToEdit({ ...partnerToEdit, link: e.target.value })
                                }} />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color={'green'} onClick={_ => {
                            savePartner(partnerToEdit).then(allPartners => {
                                setAllPartners(allPartners);
                                setShownPartners(allPartners);
                                categories.map((category, index) => {
                                    category.active = index === 0;
                                })
                                setEditModalOpen(false);
                            });
                        }}>{isNew ? "Speichern" : "Bearbeiten"}</Button>
                        <Button color={'red'} onClick={_ => setEditModalOpen(false)}>Abbrechen</Button>
                    </Modal.Actions>
                </Modal>
                {showImageModal()}
            </>
        )
    }

    const showPartner = () => {
        const partners = shownPartners.map((partner, index) => {
            return (
                <div style={{ margin: '1rem' }}>
                    {isAdmin() ? <Button.Group fluid>
                        <Button color={'orange'} icon={'edit'} onClick={_ => {
                            setPartnerToEdit({ ...partner });
                            setEditModalOpen(true);
                        }} />
                        <Button color={'red'} icon={'trash'} onClick={_ => {
                            setConfirmDeleteModal({
                                ...confirmDeleteModal,
                                active: true,
                                id: partner.id,
                                name: partner.name
                            });
                        }} />
                    </Button.Group> : null}
                    <Card className={'partner-card-wrapper'}
                        onClick={_ => linkExp.test(partner.link) ? window.open(partner.link, 'blank_') : history.push(partner.link)}>
                        <img src={config.BASE_IMAGE_URL + partner.image} alt={partner.image}
                            style={{ padding: '.2rem', objectFit: 'contain', height: '50%' }} />
                        <Card.Content>
                            <Card.Header>{partner.name}</Card.Header>
                            <Card.Description className={'card-overflow'}>
                                {partner.description}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button circular fluid className={"call-to-action-button"}
                                onClick={() => {
                                    // setFundingsToShow(fundingsToShow + MORE_FUNDINGS_AMOUNT);
                                }}>
                                MEHR ERFAHREN
                            </Button>
                        </Card.Content>
                    </Card>
                </div>)
        })


        return (
            <>
                <Modal open={confirmDeleteModal.active} dimmer={'blurring'}>
                    <Modal.Header>
                        {confirmDeleteModal.name} wirklich löschen?
                    </Modal.Header>
                    <Modal.Content>
                        Wollen Sie die Partner Karte <b>{confirmDeleteModal.name}</b> wirklich Löschen?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color={'red'} onClick={_ => {
                            deletePartner(confirmDeleteModal.id).then(all => {
                                setShownPartners(all);
                                setConfirmDeleteModal({
                                    id: "",
                                    name: "",
                                    active: false
                                });
                            });
                        }}>Ja</Button>
                        <Button color={'orange'} onClick={_ => setConfirmDeleteModal({
                            id: "",
                            name: "",
                            active: false
                        })}>Nein</Button>
                    </Modal.Actions>
                </Modal>
                <Card.Group centered itemsPerRow={applicationWidth <= 787 ? 1 : 3}>
                    {partners}
                </Card.Group>
            </>
        )
    }

    const showCategories = () => {
        return categories.map((category, index) => {
            return <div className={'five wide computer eight wide tablet sixteen wide mobile column'}
                key={index + " Cat"}>
                <Button circular fluid
                    style={{ "marginBottom": "20px" }}
                    className={category.active ? "call-to-action-button-blue" : "inactive-cat-button"}
                    onClick={() => {
                        let newCategoryState = categories;
                        if (index === 0 && !categories[0].active) {
                            for (let i = 1; i < categories.length; i++) {
                                newCategoryState[i].active = false;
                            }
                        } else {
                            newCategoryState[0].active = false;
                        }
                        newCategoryState[index].active = !newCategoryState[index].active;
                        setCategories([...newCategoryState]);
                        setShownPartners([...allPartners.filter(filterPartners)])
                    }}>
                    {category.name.toUpperCase()}
                </Button>
            </div>
        })
    }

    return (
        <div className={'grid ui'}>
            <TitleImageComponent
                textStore={textStore}
                namespace={namespace}
                imageLinkId={`${namespace}-title-image-id`}
                headerId={`${namespace}-title-header-id`}
                subheaderId={`${namespace}-subtitle-header-id`}
            />
            <div className={'row centered'}>
                <div className={'fourteen wide computer sixteen wide tablet mobile column'}>
                    <Breadcrumb className={"breadcrumb-container"}>
                        <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                            history.push("/");
                        }}>
                            HOME</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section
                            className={"active-breadcrumb nav-item-green"}>PARTNER</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'fourteen wide computer tablet sixteen wide mobile column text-align--center'}>
                    <h2>
                        <EditSimpleValueComponent
                            textStore={textStore}
                            id={`${namespace}-sector-2-title-id`}
                            namespace={namespace} />
                    </h2>
                </div>
            </div>
            <div className={'row centered page-row-bottom'}>
                <div className={'twelve wide computer fourteen wide tablet sixteen wide mobile column'}>
                    <p className={"text-block"}>
                        <EditSimpleValueComponent textStore={textStore} id={`${namespace}-sector-1-text-id`}
                            namespace={namespace} />
                    </p>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'twelve wide computer sixteen wide tablet mobile column'}>
                    <div className={'grid ui'}>
                        <div className={'row centered'}>
                            <div className={'five wide computer eight wide tablet sixteen wide mobile column'}>
                                <Input icon={'search'} fluid label={'Suchen'} placeholder={'...'} value={searchString}
                                    onChange={e => {
                                        if (searchString !== "" && currentAmount <= 3) {
                                            setCurrentAmount(shownPartners.length);
                                        }
                                        setSearchString(e.target.value)
                                    }} />
                            </div>
                            {showCategories()}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'fourteen wide column computer'}>
                    {isAdmin() ? <Button circular color={'blue'}
                        style={{ display: 'flex', margin: "1rem auto" }}
                        icon={'plus'}
                        onClick={() => {
                            setIsNew(true);
                            setPartnerToEdit({});
                            setEditModalOpen(true);
                        }} /> : null}
                    {showPartner()}
                </div>
            </div>
            {showEditModal()}
        </div>
    );
}))

export default PartnerPage;