import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import history from "../../helper/browserHistory";
import {getTokenFromLocalStorage, isAdmin} from "../../helper/util";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
//import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Checkbox} from "semantic-ui-react";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {createProject, deleteProject, editProject, getAllProjects} from "./FundraisingUtil";
import moment from "moment";
import config from "../../config/main.config";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {getAllPartner} from "../partner/PartnerPage";


let Editor = null
if (typeof window !== "undefined") {
    Editor = require("../../components/moap-components/TextEditor").default;
}


function uploadThumbnailImage(event, setNewProject, newProject) {
    const data = new FormData();
    data.append('image', event.target.files[0]);
    fetch(config.BASE_URL + 'blog/image', {
        method: "POST",
        body: data,
        headers: {
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                setNewProject({...newProject, thumbnail: json.link})
            });
        }
    }).catch((e) => {
        console.log(e);
    });
}

function fetchAllImages() {
    return new Promise(resolve => fetch(config.BASE_URL + "images/", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json().then(json => {
                let allImages = [];
                for (let i = 0; i < json.length; i++) {
                    allImages.push({
                        link: json[i]
                    })
                }
                return resolve(allImages)
            });

        } else {
            console.log(response.status)
        }
    }).catch(
        error => {
            console.log(error);

            if (error.status === 401) {
                history.replace("/admin-login");
            }

        }
    ));
}

const FundraisingOverviewPage = inject("stores")(observer((props) => {
    const [allProjects, setAllProjects] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [showSelectImage, setShowSelectImage] = useState(false);
    const [isUpdateProject, setIsUpdateProject] = useState(false);
    const [allImages, setAllImages] = useState([]);
    const [editAsHTML, setEditAsHTML] = useState(false);
    const [newProject, setNewProject] = useState({
        header: "",
        description: "",
        text: "",
        goal: 0,
        currentDonationAmount: 0,
        downloads: [],
        deadline: moment().format("YYYY-MM-DD"),
        thumbnail: "",
        partners: [],
        published: false,
        categories: []
    });
    const [categories, setCategories] = useState([]);
    const [partners, setPartners] = useState([]);
    const [inputError, setInputError] = useState({titleError: false, thumbnailError: false, descriptionError: false})


    useEffect(_ => {
        getAllProjects().then(allFundraisingProjects => {
            setAllProjects(allFundraisingProjects);
        });
    }, [])

    useEffect(_ => {
        if (isEdit) {
            getAllPartner().then(allPartners => {
                setPartners(allPartners)
            });
        }
    }, [isEdit])

    useEffect(_ => {
        if (showSelectImage && allImages.length === 0) {
            fetchAllImages().then(allImages => {
                setAllImages(allImages);
            })
        }
    }, [showSelectImage])

    const valueChanged = (value, type) => {
        setNewProject({...newProject, [type]: value})
    }


    if (!isAdmin()) {
        history.replace("/")
    }

    const showAllProjects = () => {
        return allProjects.map((project, index) => (
            <>
                <div className={'fifteen wide column'}>
                    <Grid className="article-card" key={index}>
                        <Grid.Row width={16} className="article-header">
                            <h1>{project.header}</h1>
                        </Grid.Row>
                        <Grid.Row width={16} className="article-subtitle">
                            {project.deadline} | {project.goal + "€"}
                        </Grid.Row>
                        <Grid.Row width={16} className="article-subtitle">
                            <p>{project.description}</p>
                        </Grid.Row>
                        <Grid.Row columns={2} className="article-subtitle">
                            <Grid.Column>
                                <input type="checkbox"
                                       value="default"
                                       checked={project.published}
                                       onChange={_ => {
                                           let newPublished = [...allProjects]
                                           newPublished[index].published = !project.published
                                           newPublished[index].deadline = newPublished[index].deadline.slice(0, newPublished[index].deadline.lastIndexOf('.'))
                                           setAllProjects(newPublished)
                                           editProject(project).then(response => {
                                               setAllProjects([...response])
                                               setNewProject({});
                                           })
                                       }}
                                /> Veröffentlicht
                            </Grid.Column>
                            <Grid.Column>
                                <p>Gelesen: {project.seen}</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column className=" overview-button-left">
                                <button className="call-to-action-inverse"
                                        onClick={_ => {
                                            project.deadline = project.deadline.slice(0, project.deadline.lastIndexOf('.'))
                                            setNewProject(project);
                                            setIsUpdateProject(true);
                                            setIsEdit(true);
                                        }}>
                                    <strong>Bearbeiten</strong>
                                </button>
                            </Grid.Column>
                            <Grid.Column className=" overview-button-right">
                                <button className="call-to-action-inverse"
                                        onClick={_ => {
                                            deleteProject(project.id).then(allProjects => {
                                                setAllProjects(allProjects);
                                            })
                                        }}>
                                    <strong>Löschen</strong>
                                </button>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </div>
            </>)
        )
    }

    const showCategories = () => {
        return categories.map((category, index) =>
            (
                <Checkbox checked={category.checked}
                          onChange={_ => {
                              let newCategories = [...categories]
                              newCategories[index].checked = true;
                              setCategories(newCategories);
                          }}
                          label={category.name}
                />
            )
        )
    }

    const showImageModal = () => {
        return (
            <Modal open={showSelectImage}>
                <Modal.Header>Bild auswählen</Modal.Header>
                <Modal.Content>
                    <div style={{columnCount: 3}}>
                        {allImages.map((image, index) => {
                            return (
                                <img key={'image ' + index} style={{cursor: 'pointer'}} onClick={_ => {
                                    setNewProject({...newProject, thumbnail: image.link})
                                    setShowSelectImage(false)
                                }} src={image.link} alt={image.name}/>

                            )
                        })}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color={'red'} onClick={_ => {
                        setShowSelectImage(false)
                    }}>Abbrechen</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    if (isEdit) {
        return (
            <div className={'grid ui'}>
                {showImageModal()}
                <div className={'row centered'}>
                    <div className={'column'}>
                        <h1 className="page-heading-text">Spendenprojekt erstellen</h1>
                        <div className={"breakline"}/>
                    </div>
                </div>
                <div className={'row centered'}>

                    <div className={'fifteen wide column'}>
                        <Form>
                            <Form.Field>
                                <h2>Titel</h2>
                                <input
                                    className={inputError.titleError ? "input-field-wrong form-control thumbnail-form"
                                        : "input-flied form-control thumbnail-form"}
                                    value={newProject.header}
                                    type="text"
                                    id="inputTitel"
                                    placeholder="Titel"
                                    onChange={e => valueChanged(e.target.value, "header")}
                                />
                            </Form.Field>
                            <Form.Field>
                                <h2>Thumbnail</h2>
                                <input
                                    className={inputError.thumbnailError ? "input-field-wrong form-control thumbnail-form"
                                        : "input-flied form-control thumbnail-form"}
                                    value={newProject.thumbnail}
                                    type="text"
                                    id="inputThumbnail"
                                    placeholder="URL..."
                                    onChange={e => valueChanged(e.target.value, "thumbnail")}
                                />
                                <div className="five wide column no-padding" style={{display: 'flex'}}>
                                    <div className="no-padding thumbnail-form" style={{width: '50%'}}>
                                        <button
                                            className="thumbnail-form call-to-action big-call-to-action thumbnail-form login-container"
                                            onClick={_ => setShowSelectImage(true)}
                                        >
                                            <b>
                                                AUSWÄHLEN
                                            </b>
                                        </button>
                                    </div>
                                    <div className="no-padding thumbnail-form" style={{width: '50%'}}>
                                        <input type="file" id="file" className="inputfile thumbnail-form"
                                               onChange={e => uploadThumbnailImage(e, setNewProject, newProject)}
                                               accept="image/*"/>
                                        <b>
                                            <label htmlFor="file"
                                                   className="call-to-action big-call-to-action thumbnail-form login-container">
                                                HOCHLADEN
                                            </label>
                                        </b>
                                    </div>
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <h2>Beschreibung</h2>
                                <input
                                    className={inputError.descriptionError ? "input-field-wrong form-control thumbnail-form"
                                        : "input-flied form-control thumbnail-form"}
                                    value={newProject.description}
                                    type="text"
                                    id="inputDescription"
                                    placeholder="Beschreibung"
                                    onChange={e => valueChanged(e.target.value, "description")}
                                />
                            </Form.Field>
                            <Form.Field>
                                <h2>Kategorien</h2>
                                {showCategories()}
                                <h2>Text</h2>
                                <div style={{display: "inline-flex", marginBottom: '5px'}}>
                                    <label style={{paddingRight: "5px"}}>Text als HTML bearbeiten:</label>
                                    <Checkbox toggle
                                              checked={editAsHTML}
                                              onChange={() => {
                                                  setEditAsHTML(!editAsHTML)
                                              }}>
                                    </Checkbox>
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <div className="edit-wrapper">
                                    {!editAsHTML ? Editor &&
                                        <Editor text={newProject.text}
                                                format={"html"}
                                                updateText={(text) => {
                                                    setNewProject({...newProject, text: text})
                                                }}/>
                                        :
                                        <div>
                                            <TextArea className={"edit-source-textarea"}
                                                      value={newProject.text}
                                                      onChange={e => valueChanged(e.target.value, "text")}
                                            />
                                        </div>}
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <h2>Bisher gesammelter Betrag</h2>
                                <input
                                    className={"input-flied form-control thumbnail-form"}
                                    value={newProject.currentDonationAmount}
                                    type="number"
                                    id="inputCurrentDonationAmount"
                                    placeholder="Bisher gesammelter Betrag in €"
                                    onChange={e => valueChanged(e.target.value, "currentDonationAmount")}
                                />
                            </Form.Field>
                            <Form.Field>
                                <h2>Zielbetrag</h2>
                                <input
                                    className={inputError.descriptionError ? "input-field-wrong form-control thumbnail-form"
                                        : "input-flied form-control thumbnail-form"}
                                    value={newProject.goal}
                                    type="number"
                                    id="inputGoal"
                                    placeholder="Ziel in €"
                                    onChange={e => valueChanged(e.target.value, "goal")}
                                />
                            </Form.Field>
                            <Form.Field>
                                <h2>Partner</h2>
                                {partners.map((partner, index) => {
                                    return (
                                        <>
                                            <Checkbox label={partner.name}
                                                      checked={newProject.partners.some(val => {
                                                          console.log("VALE_ ", val)
                                                         return val === partner.id
                                                      })}
                                                      onChange={_ => {
                                                          let newPartners = [...newProject.partners];
                                                          if (newProject.partners.some(val => val === partner.id)) {
                                                              newPartners.splice(index, 1);
                                                          } else {
                                                              newPartners.push(partner.id);
                                                          }
                                                          setNewProject({...newProject, partners: newPartners});
                                                      }}
                                            />
                                        </>

                                    )
                                })}

                            </Form.Field>
                            <Form.Field>
                                <h2>Frist</h2>
                                <input type={"datetime-local"} value={newProject.deadline}
                                       placeholder={newProject.deadline}
                                       onChange={(e) => {
                                           valueChanged(e.target.value, 'deadline')
                                       }}/>
                                <Checkbox indeterminate={false}
                                          style={{margin: "10px auto"}}
                                          checked={newProject.published}
                                          label={'Veröffentlichen'}
                                          onChange={_ => {
                                              setNewProject({...newProject, published: !newProject.published})
                                          }}/>
                            </Form.Field>

                        </Form>
                        <div className={'fifteen wide column'} style={{display: 'inline-flex'}}>
                            <button className={"call-to-action"}
                                    onClick={_ => {
                                        if (isUpdateProject) {
                                            editProject(newProject).then(response => {
                                                setAllProjects([...response])
                                                setIsEdit(false);
                                                setIsUpdateProject(false);
                                                setNewProject({});
                                            })
                                        } else {
                                            createProject(newProject).then(response => {
                                                let projects = allProjects;
                                                projects.push(response);
                                                setAllProjects([...projects])
                                                setIsEdit(false);
                                                setNewProject({});
                                            })
                                        }

                                    }}>
                                <strong>{isUpdateProject ? "Bearbeiten" : "Speichern"}</strong>
                            </button>
                            <button className={"call-to-action"}
                                    onClick={_ => {
                                        setIsEdit(false)
                                    }}>
                                <strong>Abbrechen</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'grid ui'}>
                <div className={'row centered'}>
                    <div className={'column'}>
                        <h1 className="page-heading-text">Spendenprojekte</h1>
                        <div className={"breakline"}/>
                    </div>
                </div>
                <div className={'row centered'}>
                    <div className={'five wide right floated column'}>
                        <button className="call-to-action-inverse"
                                onClick={_ => {
                                    setIsEdit(true);
                                }}>
                            <strong>Spendenprojekt erstellen</strong>
                        </button>
                    </div>
                </div>
                <div className={'row centered'}>
                    {showAllProjects()}
                </div>
            </div>
        )
    }

}));

export default FundraisingOverviewPage;